import 'bootstrap';
import './App.css';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import {Pricing, Character } from './pages/Pricing';
import About from './pages/About';
import TermsOfService from './pages/TermsOfService';
import Contact from './pages/Contact';
import React, { useCallback, useEffect, useState } from 'react';
import Modals from './modals/Modals';
import Article from './pages/Article';
import Cart from './pages/Cart';
import PrivateRoute from './components/PrivateRoute';
import dialogs from './dialogs';
import { useLogout, useSession } from './contexts/AuthProvider';
import Profile from './pages/Profile';
import { useConfirm } from './contexts/DialogProvider';
import * as articlesApi from './api/articles';

function Menu({display, handleClick}) {
  return (
    <div className='appmenu' style={{display: display}}>
        <nav className="navbar navbar-expand-lg navbar-dark justify-content-center">
          <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0 justify-content-center align-items-center">
              <li className="nav-item">
                <button className="btn my-2 my-sm-0" type="button" onClick={handleClick} name='pricing'>Pricing</button>
              </li>
              <li className="nav-item">
                <button className="btn my-2 my-sm-0" type="button" onClick={handleClick} name='about'>About</button>
              </li>
              <li className="nav-item">
                <button className="btn my-2 my-sm-0" type="button" onClick={handleClick} name='terms'>Terms Of Service</button>
              </li>
              <li className="nav-item">
                <button className="btn my-2 my-sm-0" type="button" onClick={handleClick} name='contact'>Contact</button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
  );
}

function App() {
  const navigate = useNavigate();
  const handleClick = (e) => {
    navigate('/' + e.currentTarget.name);
  }

  const { ready, user } = useSession();

  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [portraits, setPortraits] = useState([]);

  const [headers, setHeaders] = useState({});
  const onScroll = useCallback(() => {
    const { header, menu, clone } = headers;

    if(!header || !menu || !clone) return;
    const sticky = menu.offsetTop-header.clientHeight;

    const toggleButton = menu.firstChild.firstChild;
    if(!toggleButton.classList.contains("collapsed")) toggleButton.click();
    if (window.scrollY > sticky && clone.style.display === "none") clone.style.display = "flex";
    else if(window.scrollY < sticky+clone.offsetTop && clone.style.display !== "none") clone.style.display = "none";
  }, [headers]);
  window.onscroll = onScroll;

  useEffect(() => {
    const header = document.getElementsByClassName("apptop")[0];
    const menu = document.getElementsByClassName("appmenu")[1];
    const clone = header.lastChild;
    const banner = document.getElementsByClassName('appbanner')[0];

    banner.style.marginTop = `${header.offsetHeight}px`;
    setHeaders({header, menu, clone});

    const getArticles = async () => {
      const newArr = await articlesApi.getAllPortraits()
      setPortraits(newArr);
    }
    getArticles();
  }, []);
  return (
    <div className="App">
      <div className='apptop'>
        <nav className="navbar navbar-dark bg-info" id='topbar'>
          <Link to="/" className="navbar-brand">Dee Watter</Link>
          <form className="form-inline">
            <button hidden={ready} name='signin' className="btn btn-secondary my-2 my-sm-0" type="button" onClick={() => setShowSignIn(true)}>
              <i className="bi bi-box-arrow-in-right"></i>Sign In
            </button>
            <button hidden={ready} name='signup' className="btn btn-secondary my-2 my-sm-0" type="button" onClick={() => setShowSignUp(true)}>
              <i className="bi bi-person-plus-fill"></i>Sign Up
            </button>
            <Dropdown show={!ready} isAdmin={user ? user.isAdmin : false}/>
            <button hidden={!ready} name='cart' className="btn btn-secondary my-2 my-sm-0" type="button" onClick={handleClick}>
              <i className="bi bi-cart-plus-fill"></i>
            </button>
          </form>
        </nav>
        <Menu display="none" handleClick={handleClick}/>
      </div>
      <div className='appbanner'><img src={require('./resources/images/banner.png')} alt='banner' /></div>
      <Menu display="flex" handleClick={handleClick}/>
      <div className="appcontent">
        <Routes>
          <Route index element={<Home/>}/>
          <Route path='pricing'>
            <Route index element={<Pricing/>}/>
            <Route path='background' element={<PrivateRoute errorMessage={dialogs.error.login} ><Article type={'Background'}/></PrivateRoute>}/>
            <Route path='character'>
              <Route index element={<Character/>}/>
              {portraits.map((el, index) => 
                (
                  <Route path={el.Type.toLowerCase()} element={<PrivateRoute errorMessage={dialogs.error.login}>
                    <Article type={el.Type}/>
                  </PrivateRoute>} key={index}/>
                )
              )}
            </Route>
          </Route>
          <Route path='about' element={<About/>}/>
          <Route path='terms' element={<TermsOfService/>}/>
          <Route path='contact' element={<Contact/>}/>
          <Route path='profile' element={<Profile/>}/>
          <Route path='cart' element={<PrivateRoute errorMessage={dialogs.error.login}><Cart/></PrivateRoute>}/>
        </Routes>
      </div>
      <div className="appfooter">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <div className="col-md-6 d-flex align-items-center">
            <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
              <svg className="bi" width="30" height="24"></svg>
            </a>
            <span className="mb-3 mb-md-0 text-muted">© 2022 Company, Inc</span>
          </div>

          <ul className="nav col-md-2 justify-content-center list-unstyled d-flex">
            <li className="ms-3"><Link to="https://twitter.com" className="text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
              </svg></Link>
            </li>
            <li className="ms-3"><Link to="https://www.instagram.com" className="text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
              </svg></Link></li>
            <li className="ms-3"><Link to="https://facebook.com" className="text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
              </svg></Link></li>
          </ul>
        </footer>
      </div>
      <Modals showModal={{showSignIn, showSignUp}} setShowModal={{setShowSignIn, setShowSignUp}}/>
    </div>
  );
}

function Dropdown({ show, isAdmin }) {
  const logout = useLogout();
  const { setShowConfirm, confirm, setMessage, setConfirm } = useConfirm();

  const handleSignOut = useCallback(() => {
    setMessage(dialogs.confirm.signout);
    setShowConfirm(true);
  }, [setMessage, setShowConfirm]);

  useEffect(() => {
    if(confirm) logout();
    setConfirm(false);
  }, [confirm, logout, setConfirm]);

  return (
    <div className="btn-group" hidden={show}>
      <button className="btn btn-secondary dropdown-toggle my-2 my-sm-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i className="bi bi-person-fill"></i>
      </button>
      <div className="dropdown-menu">
        <Link className="dropdown-item" to="/profile"><div><i className="bi bi-person"/></div> <div>Profile</div></Link>
        <Link className="dropdown-item" to="/"><div><i className="bi bi-card-checklist"/></div> <div>Orders</div></Link>
        <Link className="dropdown-item" to="/" hidden={!isAdmin}><div><i className="bi bi-person-plus"/></div> <div>Administrator</div></Link>
        <span className="dropdown-item" onClick={handleSignOut}><div><i className="bi bi-box-arrow-left"/></div> <div>Sign out</div></span>
      </div>
    </div>
  );
}

export default App;
