import { FormProvider, useForm } from "react-hook-form";

export default function Profile() {
  const {register, handleSubmit, formState: { errors }} = useForm();

  const onSubmit = async () => {
    
  }

  const handleClick = () => {
    fetch('../mail/mail.php').then((res) => console.log(res));
  }

  return (
    <FormProvider onSubmit={handleSubmit} errors={errors} register={register}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Edit Profile</h1>
          <button onClick={handleClick}>Send Email</button>
        </form>
    </FormProvider>
  );
}